<template>
  <div v-if="content || urlPicture" class="original-view mt-6">
    <div class="original-view__wrapper">
      <template v-if="type === 'cv'">
        <a :href="urlPicture" targer="_blank" class="original-view__view-link">
          <img :src="urlPicture" class="original-view__view-picture" />
        </a>
      </template>
      <template v-if="type === 'job'">
        <p class="original-view__content">{{ content }}</p>
      </template>
    </div>
  </div>
  <div v-else class="original-view__no-data">
     {{ $t("COMMON.NO_DATA") }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'original-view',
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      urlPicture: 'cv/getUrlPicture',
      content: 'job/getContent',
    }),
  },
};
</script>

<style lang="scss">
.original-view {
  overflow: scroll;
}

.original-view__wrapper {
  max-width: 80%;
  margin: 0 auto 30px;
}

.original-view__view-picture {
  width: 100%;
}

.original-view__no-data {
  display: flex;
  justify-content: center;
  font-size: 36px;
  color: $color-dark-blue;
}
</style>